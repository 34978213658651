export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    messages: {
        en: {
            site: {
                title: 'Backpacking Food Planner'
            },
            links: {
                explore: 'Explore',
                foods: 'Foods',
                recipes: 'Recipes',
                addRecipe: 'Add Recipe',
                myRecipes: 'My Recipes',
                myTrips: 'My Trips',
                noTrips: 'You haven\'t planned any trips',
                createFirstTrip: 'Create your first trip',
                addTrip: 'Add New Trip',
                settings: 'Settings',
                login: 'Login',
                logout: 'Logout',
                loginCreateAccount: 'Login / Create Account'
            },
            form: {
                submit: 'Save',
                username: 'Username',
                password: 'Password',
                login: 'Login',
                createAccount: 'Create Account',
                createTrip: {
                    title: 'Create a Trip',
                    tripName: 'Trip Name',
                    tripNamePlaceholder: 'e.g. June 24 - June 29: John Muir Trail',
                    startDate: 'Start Date',
                    startDateHelp: 'When does your trip start?',
                    tripLength: 'Trip Length',
                    tripLengthPlaceholder: 'e.g. 5',
                    tripLengthHelp: 'How many days will you be on trail?',
                    dailyCalorieTarget: 'Daily Calorie Target',
                    calorieTargetPlaceholder: 'e.g. 3000',
                    calorieTargetHelp: 'Not sure? Try the ',
                    calorieTargetHelpLink: 'Mayo Clinic Calorie Calculator',
                    submit: '@:form.submit'
                },
                updateTrip: {
                    calorieTarget: 'Calorie Targets',
                    dailyCalorieTarget: 'Daily Target',
                    firstDayCalorieTarget: 'First Day Override',
                    firstDayCalorieTargetHelp: 'How many calories are you targeting on day 1?',
                    lastDayCalorieTarget: 'Last Day Override',
                    lastDayCalorieTargetHelp: 'How many calories are you targeting on the last day?',
                },
                recipe: {
                    name: 'Name',
                    instructions: '@:recipe.instructions',
                    home_instructions: '@:recipe.home_instructions',
                    tags: '@:recipe.tags',
                    privacy: 'Privacy',
                    servings: 'Servings',
                    ingredients: 'Ingredients',
                    addIngredient: '+ Add Ingredient',
                    addTag: '+ Add Tag',
                    submit: '@:form.submit',
                    prep: '@:recipe.prep'
                }
            },
            recipe: {
                ingredients: 'Ingredients',
                instructions: 'On-Trail Instructions',
                home_instructions: 'At-Home Instructions',
                prep: 'Prep Method',
                tags: 'Tags',
                textFilter: 'Search'
            },
            footer: {
                copyright: `Copyright © ${new Date().getFullYear()}`,
                briceJohnson: 'Brice Johnson',
                acknowledgements: 'Acknowledgements'
            }
        },
        'en-GIRL': {
            site: {
                title: 'Tapas for Treks'
            },
            links: {
                explore: 'What\'s the Tea?',
                foods: 'Yummy Foods!',
                recipes: 'Fab Recipes!',
                addRecipe: 'Throw a new recipe into the mix 💫',
                myRecipes: 'My Totes Delish Recipes',
                myTrips: 'Bestie Trips',
                noTrips: 'Girl, you haven\'t even planned any trips yet! 🙈✨',
                addTrip: 'Add New Glam Trip',
                createFirstTrip: 'OMG you have not trips planned, let\'s fix that!',
                settings: 'My Truth',
                logout: 'Detox Myself',
                login: 'Let me in',
                loginCreateAccount: 'Time to bounce'
            },
            form: {
                submit: 'Affirm',
                username: 'Like what do we call you?',
                password: 'Top-secret deets',
                login: 'I am present',
                createAccount: 'Create Account, duh!',
                createTrip: {
                    title: 'GIRL\'S TRIP!!',
                    tripName: 'Name your totally fab adventure',
                    tripNamePlaceholder: 'Bestie Getaway',
                    startDate: 'Like when does it start?',
                    startDateHelp: '',
                    tripLength: 'Like how long will it be?',
                    tripLengthPlaceholder: 'OMG it\'s so long!',
                    tripLengthHelp: '',
                    calorieTarget: 'Like how much are you going to eat a day?',
                    calorieTargetPlaceholder: '3000 calories? IDK',
                    calorieTargetHelp: 'Totes confused? Try like, the ',
                    calorieTargetHelpLink: 'Mayo Clinic Calorie Calculator, fer sure!',
                    submit: '@:form.submit'
                },
                recipe: {
                    name: 'What\'s it called?',
                    instructions: 'How do we make it?',
                    privacy: 'Who should be able to see it?',
                    servings: 'Servies',
                    ingredients: '@:recipe.ingredients',
                    addIngredient: '+ Add More @:recipe.ingredients',
                    submit: '@:form.submit'
                }
            },
            recipe: {
                ingredients: 'Num Nums',
                instructions: 'Instructions'
            },
            footer: {
                copyright: 'Handcrafted with ❤️ by ',
                briceJohnson: 'Brice 💪',
                acknowledgements: 'Acknolies'
            }
        }
    }
}))
